html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Press Start 2P', cursive;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #87ceeb;
}

/* Center all wallet connection elements */
.wallet-connection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fullscreen height */
}

.connect-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #ffcc00;
  border: 3px solid #ff6600;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.connect-button:hover {
  background-color: #ff9933;
}

.status {
  font-size: 10px;
}

.game-container {
  display: flex; /* Flexbox layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #87ceeb;
  overflow: hidden;
}

/* Center the dog on the screen */
.dog {
  width: 80px;
  height: 80px;
  background-size: cover;
  position: absolute;
  cursor: pointer;
}

/* Scoreboard is placed at the top and horizontally centered */
.scoreboard {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background-color: #ffcc00;
  color: #333;
  padding: 15px 30px;
  border-radius: 25px;
  border: 5px solid #ff6600;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Center wallet connection elements */
.wallet-connection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fullscreen height */
}

/* Styling for buttons */
.connect-button,
.disconnect-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #ffcc00;
  border: 3px solid #ff6600;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
}

.connect-button:hover,
.disconnect-button:hover {
  background-color: #ff9933;
}

/* Fullscreen red glow effect for bad dog */
.red-glow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 0, 0, 0.5);
  z-index: 1000;
  pointer-events: none;
  animation: fadeOut 1s forwards;
}

/* Optional fade-out animation */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Fullscreen Menu Overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Ensure it's above everything */
}

.menu-content {
  text-align: center;
  color: #fff;
}

.menu-button {
  background-color: #ffcc00;
  color: #333;
  padding: 15px 30px;
  margin: 10px;
  font-size: 12px;
  border: 3px solid #ff6600;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Press Start 2P', cursive; /* Apply game-like font to the scoreboard */
}

.menu-button:hover {
  background-color: #ff9933;
}

/* Burger Menu */
.burger-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1500; /* Ensure it's above the game elements */
}
