/* Import the game-like font "Press Start 2P" from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  background-color: #87ceeb; /* Match the game's background color */
}

.whitepaper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: #87ceeb;
}

.whitepaper-content {
  background-color: #ffcc00;
  color: #333;
  padding: 30px;
  width: 80%;
  height: 80%;
  max-width: 800px;
  border-radius: 25px;
  border: 5px solid #ff6600;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  font-family: 'Press Start 2P', cursive;
}

.whitepaper-content h1 {
  font-size: 32px;
  text-align: center;
}

.whitepaper-content h2 {
  font-size: 24px;
  margin-top: 20px;
}

.whitepaper-content p {
  font-size: 12px;
  line-height: 1.6;
}

.whitepaper-content p:last-child {
  margin-bottom: 0;
}

/* Make the content scrollable on mobile */
@media (max-width: 768px) {
  .whitepaper-content {
    width: 90%;
    padding: 20px;
  }

  .whitepaper-content h1 {
    font-size: 24px;
  }

  .whitepaper-content h2 {
    font-size: 20px;
  }

  .whitepaper-content p {
    font-size: 14px;
  }
}
