.leaderboard-container {
  width: 100%;
  max-width: 800px;
  margin: 50px auto;
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Press Start 2P', cursive;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.leaderboard-table th,
.leaderboard-table td {
  border: 2px solid #ffcc00;
  padding: 10px;
  font-size: 12px;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowed content */
}

.leaderboard-table th {
  background-color: #ff6600;
  color: #fff;
}

.leaderboard-table tr:nth-child(even) {
  background-color: #f2f2f2;
  width: auto;
}

.leaderboard-table td {
  color: #333;
}

/* Mobile Responsive Styles */
@media (max-width: 600px) {
  .leaderboard-container {
    padding: 10px;
    width: 80%;
  }

  .leaderboard-table th,
  .leaderboard-table td {
    display: block;
    padding: 5px;
    font-size: 10px;
    text-overflow: ellipsis;
    width: 70%;
  }

  .leaderboard-table th {
    font-size: 12px;
    max-width: 70%;
    text-align: center;
  }

  .leaderboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .leaderboard-table td {
    color: #333;
  }
}
