/* Import the game-like font "Press Start 2P" from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Press Start 2P', cursive; /* Set game-like font globally */
  overflow-x: hidden;
}

.game-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #87ceeb;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dog {
  width: 80px;
  height: 80px;
  background-size: cover;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.scoreboard {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background-color: #ffcc00;
  color: #333;
  padding: 15px 30px;
  width: 80%;
  max-width: 300px;
  border-radius: 25px;
  border: 5px solid #ff6600;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.scoreboard p {
  margin: 0;
}

#score {
  font-size: 32px;
  color: #ff0033; /* Set the score number to a bright red color */
}

.red-glow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 0, 0, 0.5); /* Red color with some transparency */
  z-index: 1000; /* Ensure it stays on top */
  pointer-events: none; /* Allow clicking through the glow */
  animation: fadeOut 1s forwards; /* Optional fade-out effect */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Burger Menu */
.burger-menu {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1500; /* Ensure it's above the game elements */
}

.burger-icon {
  width: 30px;
  height: 5px;
  background-color: #ff6600;
  margin: 6px 0;
  transition: 0.4s;
}

/* Fullscreen Menu Overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Ensure it's above everything */
}

.menu-content {
  text-align: center;
  color: #fff;
}

.menu-button {
  background-color: #ffcc00;
  color: #333;
  padding: 15px 30px;
  margin: 10px;
  font-size: 12px;
  border: 3px solid #ff6600;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Press Start 2P', cursive; /* Apply game-like font to the scoreboard */
}

.menu-button:hover {
  background-color: #ff9933;
}

.footer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 8px;
  background-color: #ffcc00;
  color: #333;
  padding: 15px 20px;
  width: 60%;
  border-radius: 10px;
  border: 3px solid #ff6600;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.telegram {
  display: flex;
  gap: 24px;
  margin-left: 5px;
  margin-top: 40px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Move the scoreboard lower for mobile */
  .scoreboard {
    top: 80px; /* Lower the scoreboard */
    font-size: 18px;
    width: 90%;
    margin-top: 40px;
    padding: 20px 20px;
  }

  .dog {
    width: 80px;
    height: 80px;
  }

  /* Move the footer higher for mobile */
  .footer {
    bottom: 80px; /* Raise the footer */
    font-size: 6px;
    padding: 10px 15px;
  }

  .menu-button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .burger-menu {
    bottom: 80px;
    right: 15px;
  }

  .game-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #87ceeb;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

/* Offline overlay styles */
.offline-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000; /* Ensure it's on top of everything */
  color: #fff;
}

.offline-message {
  font-size: 24px;
  padding: 20px;
  width: 80%;
  background-color: #ffcc00;
  color: #333;
  border-radius: 10px;
  border: 3px solid #ff6600;
  text-align: center;
}

/* WOOF Animation */
.woof {
  position: absolute;
  font-size: 32px;
  font-family: 'Press Start 2P', cursive;
  color: #ff6600;
  animation: fadeOutAndMoveUp 1s forwards;
  z-index: 1000; /* Ensure it's above the dog */
}

/* Keyframes for fade-out and move-up effect */
@keyframes fadeOutAndMoveUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px); /* Moves the WOOF text upwards */
  }
}
